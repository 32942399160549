import { useDispatch, useSelector } from "react-redux";
import {
  equal,
  getObject,
  head,
  keys,
  last,
  length,
  values,
} from "../utils/javascript";
import validation from "../utils/validation";
import { SET_FORM_DATA, SET_FORM_ERROR_DATA } from "../redux/constant";
import { useEffect, useState } from "react";
import { notEmpty } from "../utils/regex";
const FormContainer = ({
  attribute,
  formPath,
  defaultValues,
  isResetValues,
}) => {
  const dispatch = useDispatch();
  const formValues = useSelector(
    (state) => state?.form?.formValues?.[formPath?.parent],
  );
  const formError = useSelector(
    (state) => state?.form?.formError?.[formPath?.parent],
  );
  const [formData, setFormData] = useState(defaultValues);

  useEffect(() => {
    if (!isResetValues && !equal(length(keys(formValues)), 0)) {
      setFormData({ ...formValues });
    } else {
      setFormData(() => defaultValues);
    }
  }, [defaultValues]);

  const validate = (name, value, password) => {
    const { isRequired, pattern, error } = getObject(attribute, name);
    const res = validation(pattern, value, password || formData?.password);
    if (isRequired) {
      if (equal(typeof value, "object")) return null;
      if (!notEmpty(value)) {
        return head(error);
      }
    } else if (!!value && !res) {
      return last(error);
    }
    if (isRequired && !res) {
      return last(error);
    }
    return null;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const default_logo_id = e?.target?.default_logo_id;

    setFormData({
      ...formData,
      [name]: value,
      ...(equal(name, "name") && { default_logo_id }),
    });
    dispatch({
      type: SET_FORM_DATA,
      payload: {
        [formPath?.parent]: {
          ...formData,
          [name]: value,
          ...{ default_logo_id },
        },
      },
    });

    dispatch({
      type: SET_FORM_ERROR_DATA,
      payload: {
        [formPath?.parent]: { ...formError, [name]: validate(name, value) },
      },
    });
  };

  const isFormError = values(formError)?.some((val) => val);

  return { formData, formError, isFormError, handleChange, validate };
};

export default FormContainer;
